import React , {useState, useEffect}from 'react'
import axios from 'axios'
import { BaseUrl } from '../constants/BaseUrl'
import { Navigate, useNavigate } from 'react-router-dom'

export default function loginScreen() {
  const navigate= useNavigate()
  const [data, setData] = useState()
  const [error, setError] = useState()
  const [errorView, setErrorView] = useState(false)
  const onChange=(e)=>{
    e.preventDefault();
    const login={...data}
    login[e.target.name]=e.target.value
    setData(login)
  }
  const onLogin = async() => {
    console.log(data)
    if (data.email !== "" && data.password !== "") {
      const apiData = { ...data };
      console.log("dataaa", apiData);
      await axios
        .post(`${BaseUrl}login`, apiData)
        .then(async(res) => {
          let response =res.data
          if (res.data && res.data.token){
          console.log(res.data)
          localStorage.setItem("token", response.token);
          window.location.replace(`${process.env.PUBLIC_URL}/dashboard`)
        }
        })
        .catch((err) => {
          console.log("erorrrrrrrrrrrrrrrr", err.response.data.message  );
          setError(err.response.data.message)
          setErrorView(true)
          setTimeout(() => {
            setErrorView(false)
          }, 3000);
          
        });
    } else {
      setError1(true);
    }
  };
  return (
    <>
     <div className="centered">
                <h3>Sign In</h3>
                <div className="form-group">
                    <label style={{color:"whitesmoke"}}>Email address</label>
                    <input onChange={e=>onChange(e)} style={{margin:'10px'}} name="email" required type="email" className="form-control" placeholder="Enter email" />
                </div>
                <div className="form-group">
                    <label style={{color:"whitesmoke"}}>Password</label>
                    <input onChange={e=>onChange(e)} style={{margin:'10px'}} name="password" type="password" className="form-control" placeholder="Enter password" />
                </div>
                {errorView&&<span style={{color:'red'}}>{error}</span>}<br/>
                <button onClick={onLogin} style={{backgroundColor:'#b6c586'}} type="submit" className="btn">Submit</button>
            </div>
            </>
  )
}
