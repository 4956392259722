
// export const BaseUrl = 'https://asian-house-fr.vercel.app/'
// export const BaseUrl = 'https://asian-house.herokuapp.com/'
// export const BaseUrl = 'https://asian-house-backend.herokuapp.com/'
export const BaseUrl = 'https://asian-house-3-6e7beec487cf.herokuapp.com/'
// export const BaseUrl = 'http://localhost:4000/'
export const token = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };
