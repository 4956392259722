import React from 'react'
import logo from '../assets/images/image-2.png'
import {Link} from 'react-router-dom'

function navBarDashboard() {
  return (
    // <div class="navbar">
    <div >
      <Link to={`/`}><img
            className="bg-logo mb-2 ng-star-inserted"
            src={logo}
            alt="logo"
            style={{width:'140px', height:'140px', marginLeft:'20px'}}
          />
          </Link>
          </div>
//   </div>
  )
}

export default navBarDashboard