// import logo from './logo.svg';
import './App.css';
import { Routes, Route, Link } from "react-router-dom";
import Home from './components/Home';
import My404Component from './components/My404Component'
import LoginScreen from './components/loginScreen';
import Dashboard from './components/dashboard'

function App() {
  return (
    <div className="App">
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path='/login'  element={<LoginScreen/>} />
        <Route path="/dashboard" element={<Dashboard />} />
        {/* <Route path='*' exact={true} element={<My404Component/>} /> */}
        {/* <Route path="about" element={<About />} /> */}
      </Routes>
    </div>
  );
}

export default App;
