import React, { useState, useEffect, Component } from "react";
import NavBarDashboard from "./navBarDashboard";
import { Navigate } from "react-router-dom";
import MaterialTables from "./MaterialTable";
import axios from "axios";
import { BaseUrl, token } from "../constants/BaseUrl";
import moment from "moment";

export class dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      UsersList: [],
      users: [],
    };
  }

  componentDidMount() {
    axios
      .get(`${BaseUrl}email/get-gift-data`, token)
      .then((response) => {
        console.log(response.data.data);
        this.setState({ users: response.data.data });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    const token = localStorage.getItem("token");
    if (!token) {
      return <Navigate to="/login" />;
    }
    const { users } = this.state;
    console.log("ashdajshdj", users && users);
    const columnsData = [
      { title: "Email", field: "email" },
      { title: "Gift", field: "gift" },
      { title: "Name", field: "name" },
      {
        title: "Date",
        field: "createdAt",
        render: (rowData) => {
          const button = <div>{moment(rowData.createdAt).format('MMMM Do YYYY')}</div>;
          return button;
        },
      },
    ];
    return (
      <div>
        <NavBarDashboard />
        <div style={{ margin: "20px", background: "#333" }}>
          <MaterialTables
            columns={columnsData}
            data={users}
            filtering={true}
            search={true}
            title={"List Of Gift Data"}
            exports={true}
          />
        </div>
      </div>
    );
  }
}

export default dashboard;
